import React from 'react'
import cn from 'classnames'

const specialOverrides = ['border', 'text', 'list']

export const twOverride = (...classes) => {
  const classNamesList = classes.flatMap(c => c.split(' '))

  const singles = [...classNamesList.filter(c => c.indexOf('-') < 0)]

  const overrides = classNamesList
    .filter(c => c.indexOf('-') > 0)
    .reverse()
    .reduce((seen, cl) => {
      const dashIndex = cl.indexOf('-')
      let firstPart = cl.substr(0, dashIndex)

      if (specialOverrides.includes(firstPart)) {
        firstPart = cl.substr(cl.lastIndexOf('-'))
      }

      if (!seen[firstPart]) seen[firstPart] = cl

      return seen
    }, {})

  return [...Object.values(overrides).reverse(), ...singles].join(' ')
}

export const twcn = (...classes) => twOverride(cn(...classes))

/** @returns {import('react').RefForwardingComponent<any, any>} */
export const styled = (Tag, ...twClasses) =>
  React.forwardRef(({ children = null, className = '', ...rest }, ref) => (
    <Tag className={twcn(...twClasses, className)} {...rest} ref={ref}>
      {children}
    </Tag>
  ))

const tw = (Tag, ...twClasses) => ({
  children = null,
  className = '',
  ...rest
}) => (
  <Tag className={cn(...twClasses, className)} {...rest}>
    {children}
  </Tag>
)

export default tw
